<template>
  <div class="app-container">
    <div class="toolWrap">
      <el-button type="success" icon="el-icon-plus" @click="showAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        :disabled="selecTionArr.length !== 1"
        icon="el-icon-edit"
        @click="showEdit(selecTionArr[0])"
        >编辑</el-button
      >
      <el-button
        type="danger"
        :disabled="selecTionArr.length == 0"
        icon="el-icon-delete"
        @click="handleDel"
        >删除</el-button
      >
      <div class="search">
        <el-input
          style="width: 280px"
          clearable
          placeholder="根据商户姓名查询"
          v-model="searchQuery.merchantPersonnelName"
          class="searchInput"
        >
          <template slot="prepend">商户姓名</template>
        </el-input>
        <el-input
          style="width: 280px"
          clearable
          placeholder="根据摊位号查询"
          v-model="searchQuery.stallNo"
          class="searchInput"
        >
          <template slot="prepend">摊位号</template>
        </el-input>
        <div class="notice">
          <!-- <b>温馨提示</b>：摊位归属以最新更新的合同为准！ -->
        </div>
      </div>
    </div>
    <el-table
      ref="table"
      :data="listData"
      border
      highlight-current-row
      row-key="id"
      @selection-change="getSelection($event)"
      @row-dblclick="showEdit($event)"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="merchantPersonnelName"
        label="商户姓名"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="stallNo"
        label="摊位编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="businessType"
        label="经营类型"
        width="180"
      ></el-table-column>
      <el-table-column prop="contractNo" label="合同编号"></el-table-column>
      <el-table-column
        prop="contractStartTime"
        label="合同开始时间"
      ></el-table-column>
      <el-table-column
        prop="contractEndTime"
        label="合同结束时间"
      ></el-table-column>
      <el-table-column label="到期状态">
        <template slot-scope="scope">
          <el-tag :type="scope.row.expiredStatus == 0 ? 'success' : 'danger'">{{
            scope.row.expiredStatus | StatusFilter
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="租金缴费">
        <template slot-scope="scope">
          <el-tag :type="scope.row.rentStatus == 1 ? 'success' : 'danger'">{{
            scope.row.rentStatus | payStatusFilter
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="物业缴费">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.propertyCostsStatus == 1 ? 'success' : 'danger'"
            >{{ scope.row.propertyCostsStatus | payStatusFilter }}</el-tag
          >
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="stallNo"
        label="更新时间"
        width="180"
      ></el-table-column> -->
    </el-table>
    <el-pagination
      background
      v-bind="pageInfo"
      :layout="layout"
      @size-change="onSizeChange"
      @current-change="onPageChange"
    />
    <el-dialog
      :title="handelType === 'new' ? '新增合同' : '编辑合同'"
      :visible.sync="dialogVisible"
      width="800px"
      center
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        :inline="true"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="商户姓名" prop="merchantPersonnelId">
          <el-select
            filterable
            v-model="form.merchantPersonnelId"
            :disabled="handelType === 'new' ? false : true"
            @change="setIdCardVal"
          >
            <el-option
              v-for="(item, index) in merchantPersonArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号" prop="merchantPersonnelIdCard">
          <el-input v-model="form.merchantPersonnelIdCard" disabled />
        </el-form-item>
        <el-form-item label="合同开始时间" prop="contractStartTime">
          <el-date-picker
            class="formdate"
            v-model="form.contractStartTime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="合同到期时间" prop="contractEndTime">
          <el-date-picker
            class="formdate"
            v-model="form.contractEndTime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <!-- 
        <el-form-item
    v-for="(domain, index) in dynamicValidateForm.domains"
    :label="'域名' + index"
    :key="domain.key"
    :prop="'domains.' + index + '.value'"
    :rules="{
      required: true, message: '域名不能为空', trigger: 'blur'
    }"
  > -->

        <el-form-item
          v-for="(item, index) in form.merchantContractStall"
          :key="item.marketStallId"
          :prop="'merchantContractStall.' + index + '.marketStallId'"
          :rules="{
            required: true,
            message: '请选择摊位号',
            trigger: 'change',
          }"
        >
          <div class="addWrap">
            <el-form-item prop="marketStallId">
              <el-select
                filterable
                v-model="item.marketStallId"
                placeholder="摊位"
                class="partInput"
                @change="setPayItem($event, index)"
              >
                <el-option
                  v-for="(item, indexs) in stallList"
                  :key="indexs"
                  :label="item.stallNo"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="rentMoney">
              <el-input
                v-model="item.rentMoney"
                placeholder="租金"
                class="partInput"
              ></el-input>
            </el-form-item>
            <el-form-item prop="propertyCostsMoney">
              <el-input
                v-model="item.propertyCostsMoney"
                placeholder="物业费"
                class="partInput"
                style="width: 180px"
              ></el-input>
            </el-form-item>
            <el-button
              type="success"
              size="mini"
              icon="el-icon-plus"
              circle
              @click="addStallItem"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              circle
              @click="delStallItem(index)"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="租金缴费">
          <el-radio-group v-model="form.rentStatus" class="radioWidth">
            <el-radio :label="0" border>未缴</el-radio>
            <el-radio :label="1" border>已缴</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="物业缴费">
          <el-radio-group v-model="form.propertyCostsStatus" class="radioWidth">
            <el-radio :label="0" border>未缴</el-radio>
            <el-radio :label="1" border>已缴</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="handelType === 'new'"
          type="primary"
          @click="handleAdd('ruleForm')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="handleEdit('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      ruleForm: {
        startTime: "",
      },
      rules: {
        //规则
        merchantPersonnelId: [
          //商户姓名
          { required: true, message: "请输入商户姓名", trigger: "change" },
        ],
        contractStartTime: [
          //合同开始时间
          { required: true, message: "请输入开始时间", trigger: "change" },
        ],
        contractEndTime: [
          //合同到期时间
          { required: true, message: "请输入结束时间", trigger: "change" },
        ],
        // marketStallId: [
        //   //摊位
        //   { required: true, message: "请输入摊位号", trigger: "change" },
        // ],
      },
      listData: [],
      handelType: "",
      searchQuery: { merchantPersonnelName: "", stallNo: "" },
      dialogVisible: false,
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 10,
        total: 0,
      },
      form: {
        merchantContractStall: [
          { marketStallId: "", propertyCostsMoney: "", rentMoney: "" },
        ],
        propertyCostsStatus: 0,
        rentStatus: 0,
        merchantPersonnelId: "",
      },
      typeList: [],
      stallList: [],
      selecTionArr: [],
      merchantPersonArr: [],
      layout: "sizes, prev, pager, next, jumper",
    };
  },
  filters: {
    StatusFilter(val) {
      const statusMap = { 0: "合同期内", 1: "合同过期" };
      return statusMap[val];
    },
    payStatusFilter(val) {
      const statusMap = { 0: "未缴费", 1: "已缴费" };
      return statusMap[val];
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.getDataList();
      },
      deep: true, //开启深度监听
    },
  },
  created() {
    this.getDataList();
    this.getmerchantPersonList();
    this.getStallList();
  },
  methods: {
    async getDataList() {
      let obj = Object.assign({}, this.pageInfo, this.searchQuery);

      await api.contract.list(obj).then((res) => {
        this.listData = res.data.data;
        this.pageInfo.total = parseInt(res.data.total);
        console.log(this.listData);
      });
    },
    async getmerchantPersonList() {
      await api.common
        .list({ apiName: "/merchant/personnel", pageSize: 1000 })
        .then((res) => {
          this.merchantPersonArr = res.data.data;
          console.log("商户", this.merchantPersonArr);
        });
    },
    async getStallList() {
      await api.common
        .list({ apiName: "/market/stall", pageSize: 1000 })
        .then((res) => {
          this.stallList = res.data.data;
          console.log("摊位", this.stallList);
        });
    },
    getSelection(e) {
      this.selecTionArr = e;
    },
    setIdCardVal(e) {
      let obj = this.merchantPersonArr.find((item) => item.id == e);
      this.form.merchantPersonnelIdCard = obj.idCard;
    },
    setPayItem(e, index) {
      let stallObj = this.stallList.find((item) => item.id == e);

      this.form.merchantContractStall[index].rentMoney = stallObj.stallRent;
    },

    async showEdit(obj) {
      this.handelType = "edit";
      await api.contract.detail({ id: obj.id }).then((res) => {
        console.log("edit--->", res);
        this.form = res.data;
        console.log(res.data);
      });

      this.dialogVisible = true;
    },
    handleEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.contract.update(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "更新成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showAdd() {
      this.handelType = "new";
      (this.form = {
        merchantContractStall: [
          { marketStallId: "", propertyCostsMoney: "", rentMoney: "" },
        ],
        propertyCostsStatus: 0,
        rentStatus: 0,
      }),
        (this.dialogVisible = true);
    },
    addStallItem() {
      this.form.merchantContractStall.push({
        marketStallId: "",
        propertyCostsMoney: "",
        rentMoney: "",
      });
    },
    delStallItem(num) {
      if (this.form.merchantContractStall.length == 1) {
        this.$message({ message: "至少保留一项", type: "error" });
        return;
      }
      this.form.merchantContractStall.splice(num, 1);
    },
    handleAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.contract.create(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "新增成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDel() {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let ids = [];
        this.selecTionArr.forEach((item) => {
          ids.push(item.id);
        });

        api.contract.remove({ ids: ids }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message({ message: res.message, type: "error" });
          }
          this.getDataList();
        });
      });
    },
    onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getDataList();
    },
    onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  display: inline-block;
  color: #909399;
}
.notice b {
  font-weight: normal;
  color: #409eff;
}
.toolWrap {
  padding: 20px 0;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
.formdate {
  width: 220px;
}
.partInput {
  width: 210px;
  margin-right: 10px;
}
.radioWidth {
  width: 240px;
}
.el-pagination {
  padding: 20px 0 0 0;
}
.search {
  display: inline-block;
  margin-left: 20px;
}
</style>
